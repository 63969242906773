import React, { useState } from "react"; 
import { Link } from 'react-router-dom';
import '../views/home.css'
import dna from '../assets/home-assets/dna.png'
import runner from '../assets/home-assets/runner.png'
import ballard from '../assets/home-assets/ballard.png'
import launch from '../assets/home-assets/launch.png'
import microsoft from '../assets/home-assets/microsoft.png'
import sutardja from '../assets/home-assets/sutardja.png' 
import arrow from '../assets/home-assets/arrow.png'
import Footer from '../../components/controllers/Footer'
import Navbar from '../../components/controllers/Navbar';

/* function to create one FAQ question/answer block, includes logic to show/hide answer and rotate arrow upon clicking the arrow image */
function FAQ( { question, answer}) {

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    }

    return (
        <div className = "faq-container">
            <div className = 'faq-question' onClick = {toggleVisibility}>
                <img className = {isVisible? 'arrow-visible' : 'arrow-hidden'} onClick = {toggleVisibility} src = {arrow} alt = ''></img>
                <div> {question} </div>
            </div>
            <div className = "faq-answer"> 
                <div className = {isVisible? 'faq-visible' : 'faq-hidden'}>
                    <div className = "faq-answer-text"> {answer} </div>
                </div>
            </div>
        </div>
    )
}
/* The Home component represents the entire home page, and is the default landing page*/
export default function Home() {
    return (
        <div className = 'home-page'>
            <Navbar />
            <div className = 'home'>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
                </style>
                <div className="top-container">
                    <div className="main-quote">
                        <h4>Biologically Personalized Optimization</h4>
                        <p className = "long-run"><strong>FOR THE LONG RUN</strong></p>
                        <p className = 'quote-bottom'>Science Driven Solutions & Supplementation for your Unique Athletic Profile</p>
                        <Link to ='/waitlist' className = 'join'> JOIN NOW </Link>
                    </div>
                    <img className="runner" src={runner} alt =''/>
                </div>
                <p className = 'partners-with'>PARTNERS WITH</p>
                <div className="partners">
                    <img className="sutardja" src={sutardja} alt =''/>
                    <img className="microsoft" src={microsoft} alt =''/>
                    <img className="launch" src={launch} alt =''/>
                    <img className="ballard" src={ballard} alt =''/>
                </div>
                <div className="our-vision">
                    <img className="dna" src={dna} alt =''/>
                    <div className="vision-text">
                        <h5 className="vision-title">Our Commitment to You</h5>
                        <p className="vision-body">Using science, we are dedicated to enhancing your personal health and athletic excellence. 
                            Become part of a community where ambition and a deep understanding of personal well-being are the cornerstones of 
                            sustained optimal performance.</p>
                        <Link to ='/product' className = 'learn'> Learn More </Link>
                    </div>
                </div>
                <div>
                    <h1 className="faq-heading">FREQUENTLY ASKED QUESTIONS</h1>
                    <p className="faq-subheading">Got questions? We've got the answers.</p>
                </div>
                <div className="faq-section">
                    <FAQ question = "What products are provided by OptiGenix?" 
                    answer = {<div>OptiGenix offers <strong className="bold-text-2"> personalized supplement formulas </strong>tailored to the unique biological needs and athletic goals of each athlete. By analyzing genetic variations and key athletic indicators in the blood, we pair your bodies needs with your athletic profile to create custom supplement packages designed to optimize performance and recovery in your sport. Our product range includes vitamins, minerals, amino acids, and other essential nutrients in bioavailable forms, ensuring maximum absorption and efficacy.</div>} />
                    <FAQ question = "When will the actual products be launched?"
                    answer = {<div>We are excited to announce that OptiGenix will be launching it's <strong class="bold-text-2">pilot product Fall 2024.</strong> Stay tuned for updates and sign up for our waitlist to be among the first athletes to experience our cutting-edge personalized nutrition solutions.</div>} />
                    <FAQ question = "Is OptiGenix only for Athletes?"
                    answer = {<div>Our mission is to to empower <strong class="bold-text-2">every competitor</strong> to reach their performance zenith through biologically personalized, scientifically validated, supplementation. While OptiGenix is designed with athletes in mind, our personalized supplement packages can benefit anyone looking to improve their health and wellness. Whether you're a professional athlete, a fitness enthusiast, or simply someone who wants to optimize their nutrition, our tailored solutions can help you achieve your goals and enhance your overall well-being.</div>} />
                    <FAQ question = "Why choose OptiGenix over others?"
                    answer = {<div>OptiGenix stands out because of our unique scientific and athletic approach to personalized nutrition. Unlike other supplement providers, we use <strong class="bold-text-2">advanced genetic testing</strong> and <strong class="bold-text-2">FDA approved blood analyses </strong>to create custom supplement packages designed to enhance your athletic performance and recovery. Our commitment to purity, bioavailability, and proven efficacy ensures that you receive the highest quality products that genuinely impact your health and help you achieve peak performance. With OptiGenix, you get supplements that are not only personalized but also optimized for athletic excellence.</div>} />
                    <FAQ question = "How does the personalization process work?"
                    answer = {<div>Our process begins with a <strong class="bold-text-2">simple at-home test kit</strong> that collects your genetic and blood sample. Once our FDA approved lab receives your sample, our team of experts analyze your biological data, along with the athletic information from our intake form, to identify your specific nutritional needs. Based on this analysis, we formulate a personalized supplement package that is tailored to optimize your performance and health.</div>} />
                </div>
                <Footer />
            </div>
        </div>
    );
}