import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa';
import '../views/Navbar.css';
import logo from '../assets/navbar-assets/logo.png'

/* The Navbar component is on the top of every page, and lets the uses switch to any page on the site by clicking the respective link */

function Navbar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMenu = () => setClick(false);
    return (
        <>
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
        </style>
        <div className='navbar'>
            <div className='navbar-left'>
                    <img src={logo} className='logo' alt='logo' />
                    <Link to='/'><h1 className='opti'>OptiGenix</h1></Link>
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
                {/*<li>
                    <Link to ='/' className = 'navbar-text'> HOME </Link>
                </li>*/}
                <li>
                    <Link to ='/product' className = {click ? "navbar-text-active" : "navbar-text"} onClick={closeMenu}> PRODUCT </Link>
                </li>
                <li>
                    <Link to ='/about' className = {click ? "navbar-text-active" : "navbar-text"} onClick={closeMenu}> ABOUT US</Link>
                </li>
                <li>
                    <Link to ='/waitlist' className = {click ? "waitlist-button-active" : "waitlist-button"} onClick={closeMenu}> WAITLIST </Link>
                </li>
            </ul>
            <div className = "hamburger" onClick={handleClick}>
                {click ? (<FaTimes size = {20} style = {{color: '#fff'}}/>) : (<FaBars size = {20} style = {{color: '#fff'}}/>)}
            </div>
        </div>
        </>
    )
}

export default Navbar;