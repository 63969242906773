import React from "react"; 
import { Link } from 'react-router-dom';
import '../views/Footer.css'
import logo from '../assets/footer-assets/logo.png'
import linkedin from '../assets/footer-assets/linkedin.png'
import instagram from '../assets/footer-assets/instagram.png'

/* returns reusable Footer component */
export default function Footer() {
    return (
        <div className = "footer">
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
            </style>
            <div className="footer-top">
                <div className="footer-top-container">
                    <div className="logo-container">
                        <img src={logo} className='footer-logo' alt='logo' />
                        <Link to='/'><h1 className='opti'>OptiGenix</h1></Link>
                    </div>
                    <ul className="list-of-links">
                        <li>
                            <Link to ='/' className = 'footer-text'> Home </Link>
                        </li>
                        <li>
                            <Link to ='/product' className = 'footer-text'> Product </Link>
                        </li>
                        <li>
                            <Link to ='/about' className = 'footer-text'> About Us</Link>
                        </li>
                        <li>
                            <Link to ='/waitlist' className = 'footer-text'> Join the Waitlist </Link>
                        </li>
                    </ul>
                    <div className="socials-container">
                        <a href="https://www.linkedin.com/company/optigenix/" target="_blank" rel="noopener noreferrer">
                            <img className="social-icon" src={linkedin} alt='' />
                        </a>
                        <a href="https://www.instagram.com/optigenix/" target="_blank" rel="noopener noreferrer">
                            <img className="social-icon" src={instagram} alt='' />
                        </a>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <p class="footer-bottom-text">Copyright © 2024 OptiGenix | All Rights Reserved</p>
            </div>
        </div>
    );
}


