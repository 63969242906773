import React from 'react';
import Home from './pages/controllers/home';
import About from './pages/controllers/about';
import Product from './pages/controllers/product';
import Waitlist from './pages/controllers/waitlist';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <Router>
      <div className='pages'>
      <Routes>
        <Route path='/' element = {<Home/>}/>
        <Route path='/about' element = {<About/>}/>
        <Route path='/product' element = {<Product/>}/>
        <Route path='/waitlist' element = {<Waitlist/>}/>
      </Routes>
      </div>
    </Router>
  );
}

export default App;
