import React from "react"; 
import '../views/about.css'
import alec from '../assets/about-assets/alec.jpg'
import cecelia from '../assets/about-assets/cecelia.png'
import jai from '../assets/about-assets/jai.png'
import gabe from '../assets/about-assets/gabe.png'
import laura from '../assets/about-assets/laura.png'
import naveed from '../assets/about-assets/naveed.jpeg'
import Navbar from '../../components/controllers/Navbar';
import Footer from '../../components/controllers/Footer';

/* the About function returns the component used for the entire about us page */
export default function About() {
    return (
        <div className="about-page">
            <Navbar />
            <section className="team-circles">
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
                </style>
                <div className="container">
                    <h2 className="centered-heading">Meet Our Team</h2>
                    <p className="centered-subheading">Our team at is a unique blend of elite athletes and dedicated scientists, united by a common goal to revolutionize personalized sports nutrition.</p>
                
                    <div className="team-grid">
                        <div className="team-card">
                            <img className="team-image" src={gabe} alt =''/>
                            <div className="name-container"><strong className="name-text">Gabriel Abbes</strong></div>
                            <div className="position-container"><strong className="position-text">Co-Founder & CEO</strong></div>
                            <p className="description">4 time varsity captain on the <br/>Cal Cross Country and Track Team, Business Administration <br/>at Berkeley Haas</p>
                        </div>
                        <div className="team-card">
                            <img className="team-image" src={jai} alt =''/>
                            <div className="name-container"><strong className="name-text">Jai Williams</strong></div>
                            <div className="position-container"><strong className="position-text">Co-Founder & COO</strong></div>
                            <p className="description">Former D1 Track and Field Athlete, 2 Time Podium Finisher in the Pac-12 Conference of Champions, Business Administration at Berkeley Haas</p>
                        </div>
                        <div className="team-card">
                            <img className="team-image" src={laura} alt =''/>
                            <div className="name-container"><strong className="name-text">Laura Glickman, PhD</strong></div>
                            <div className="position-container"><strong className="position-text">Scientific and <br/>Strategic Advisor</strong></div>
                            <p className="description">Seasoned Biotech Entrepreneur<br/>and Advisor</p>
                        </div>
                        <div className="team-card">
                            <img className="team-image" src={alec} alt =''/>
                            <div className="name-container"><strong className="name-text">Alec Bizieff, PhD</strong></div>
                            <div className="position-container"><strong className="position-text">Researcher</strong></div>
                            <p className="description">Research Scientist at UCSD, <br/>UC Berkeley PhD in Metabolic Biology</p>
                        </div>
                        <div className="team-card">
                            <img className="team-image" src={naveed} alt =''/>
                            <div className="name-container"><strong className="name-text">Naveed Ziari, <br/>PhD Candidate 2025</strong></div>
                            <div className="position-container"><strong className="position-text">Researcher</strong></div>
                            <p className="description">UC Berkeley PhD in <br/>Computational Biology, <br/>Mass Spectrometrist</p>
                        </div>
                        <div className="team-card">
                            <img className="team-image" src={cecelia} alt =''/>
                            <div className="name-container"><strong className="name-text">Cecelia Zielke,<br/>PhD Candidate 2027</strong></div>
                            <div className="position-container"><strong className="position-text">Researcher</strong></div>
                            <p className="description">Endocrinology PhD Student <br/>at the UC Berkeley,<br/>Research Assistant CWRU SOM</p>
                        </div>
                    </div>            
                </div>
            </section>
            <Footer />
        </div>
    );
}