import React from "react"; 
import '../views/product.css';
import steps from '../assets/product-assets/steps.png';
import stepsMobile from '../assets/product-assets/stepsMobile.png';
import received from '../assets/product-assets/received.png';
import receivedMobile from '../assets/product-assets/receivedMobile.png';
import Navbar from '../../components/controllers/Navbar';
import Footer from '../../components/controllers/Footer';


/* the Product function returns the component used for the entire product page */
export default function Product() {
    return (
        <div className='product-page'>
            <Navbar />
            <section class="product">
                <div>
                    <h1 class="title">We Take the Guess&nbsp;Work<br/><strong class="bold-text">OUT</strong><br/>of Athletic Supplementation</h1>
                </div>
                <picture className="steps">
                    <source media="(max-width: 800px)" srcset={stepsMobile} className='steps'/>
                    <img src={steps} className='steps' alt="" />
                </picture>
                <div className = "product-container">
                    <div class="product-square">
                        <picture className="product-img">
                            <source media="(max-width: 800px)" srcset={receivedMobile} className='product-img'/>
                            <img src={received} className='product-img' alt="" />
                        </picture>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}